import "./stylesheets/admin.scss";
import "./stylesheets/availability_visualisation.scss";

// Configure Stimulus
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const application = Application.start();

import "src/admin_cable";

const sharedContext = require.context("controllers/shared", true, /\.(ts|js|tsx|jsx)$/);
application.load(definitionsFromContext(sharedContext));

const adminContext = require.context("controllers/admin", true, /\.(ts|js|tsx|jsx)$/);
application.load(definitionsFromContext(adminContext));

window.addEventListener('wheel', (event) => {
  if (event.target.type == 'number')
    event.target.blur();
});
